/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
     
            {
                id: 'dashboards.report',
                title: 'Intent Viewer',
                type: 'basic',
                icon: 'heroicons_outline:squares-2x2',
                link: '/dashboards/intentviewer',
                badge: {
                    title: 'NEW',
                    classes: 'px-2.5 text-secondary-badge font-bold',
                },
            },
            {
                id: 'dashboards.technology-research-hub',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:map-pin',
                link: '/dashboards/technology-research-hub',
                badge: {
                    title: 'NEW',
                    classes: 'px-2.5 text-secondary-badge font-bold',
                },
            }, {
                id: 'dashboards.campaign-viewer',
                title: 'Market Viewer',
                type: 'basic',
                icon: 'heroicons_outline:building-storefront',
                link: '/dashboards/campaign-viewer',
                badge: {
                    title: 'coming soon',
                    classes: 'px-2 bg-secondary text-white rounded-full',
                },
            },
           /* {
                id: 'dashboards.autonachversand',
                title: 'Autonachversand',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/autonachversand'
            },*/
        ]
    },
   {
        id: 'plugins',
        title: 'Plugins',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
           {
                id: 'web-tracking',
                title: 'Traffic Insights',
                type: 'basic',
                icon: 'heroicons_outline:cursor-arrow-rays',
                link: '/web-tracking'
            }/*
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },*/
           
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/marketing'
            },

        ]
    },
    {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'example',
                title: 'Leere Seite',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/example'
            },
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },
            {
                id: 'prospectviewer',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/prospectviewer'
            },
        ]
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/marketing'
            },

        ]
    },
    {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'example',
                title: 'Leere Seite',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/example'
            },
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },
            {
                id: 'prospectviewer',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/prospectviewer'
            },
        ]
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: null,
        type: 'group',
        icon: null,
        children: [
            {
                id: 'dashboards.marketing',
                title: 'Marketing',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/marketing'
            },

        ]
    },
    {
        id: 'applications',
        title: 'Anwendungen',
        subtitle: null,
        type: 'group',
        icon: null,
        children: [
            {
                id: 'example',
                title: 'Leere Seite',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/example'
            },
            {
                id: 'campaigns',
                title: 'Kampagnen',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/campaigns'
            },
            {
                id: 'prospectviewer',
                title: 'Prospect Viewer',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/prospectviewer'
            },
        ]
    }
];
