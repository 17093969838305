import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { MatDatepickerIntl } from '@angular/material/datepicker';


@Injectable({
	providedIn: 'root'
})
export class CustomDatepickerIntl extends MatDatepickerIntl {


	calendarLabel = 'C';  // "Calendar" Text
	closeCalendarLabel = 'CC';      // "Close calendar" Text
	comparisonDateLabel = 'CR';    // "Comparison range" Text
	nextMonthLabel = 'NM';        // "Next month" Text
	nextMultiYearLabel = 'NTFY';       // "Next 24 Years" Text
	nextYearLabel = 'NY';       // "Next year" Text
	openCalendarLabel = 'OC';       // "Open calendar" Text
	prevMonthLabel = 'PM';       // "Previous month" Text
	prevMultiYearLabel = 'PTFY';       // "Previous 24 years" Text
	prevYearLabel = 'PY';       // "Previous year" Text
	switchToMonthViewLabel = 'CD';       // "Choose date" Text
	switchToMultiYearViewLabel = 'CMAY';       // "Choose month and year" Text

	constructor(private _transloco: TranslocoService) {
		super();
		this._transloco.langChanges$.pipe(takeUntilDestroyed()).subscribe((change) => {
			this.calendarLabel = this._transloco.translate("datepicker.calendar");
			this.closeCalendarLabel = this._transloco.translate("datepicker.close-calendar");
			this.comparisonDateLabel = this._transloco.translate("datepicker.comparison-date");
			this.nextMonthLabel = this._transloco.translate("datepicker.next-month");
			this.nextMultiYearLabel = this._transloco.translate("datepicker.next-multi-year");
			this.nextYearLabel = this._transloco.translate("datepicker.next-year");
			this.openCalendarLabel = this._transloco.translate("datepicker.open-calendar");
			this.prevMonthLabel = this._transloco.translate("datepicker.prev-month");
			this.prevMultiYearLabel = this._transloco.translate("datepicker.prev-multi-year");
			this.prevYearLabel = this._transloco.translate("datepicker.prev-year");
			this.switchToMonthViewLabel = this._transloco.translate("datepicker.switch-to-month-view");
			this.switchToMultiYearViewLabel = this._transloco.translate("datepicker.switch-to-multi-year-view");
			this.changes.next();
		});
	}

	//ToDo: Implement Range Methods
	
}
