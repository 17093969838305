import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';

// prettier-ignore
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'home'
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            // {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            //Settings
            { path: 'settings', loadChildren: () => import('app/modules/settings/settings.routes') },
            //Home
            { path: 'home', loadChildren: () => import('app/modules/home/home/home.routes') },
            {
                path: 'plugins', children: [
                    {
                        path: 'traffic-insights', children: [

                            { path: '', loadChildren: () => import('app/modules/plugins/traffic-insights/traffic-insights.routes') },
                            { path: 'download', loadChildren: () => import('app/modules/plugins/traffic-insights/download/download.routes') },
                        ] 
                    }
                ]
            },
            // Dashboards
            {
                path: 'dashboards', children: [
                    {
                        path: 'intentviewer', children: [{
                            path: '', loadChildren: () => import('app/modules/dashboards/intentviewer/intentviewer.routes')
                        },
                            {
                                path: 'products', loadChildren: () => import('app/modules/dashboards/intentviewer/products-overview/products-overview.routes')
                            }]
                    },
                    { path: 'trafficviewer', loadChildren: () => import('app/modules/dashboards/trafficviewer/trafficviewer.routes') },
                    { path: 'reportviewer', loadChildren: () => import('app/modules/dashboards/reportviewer/reportviewer.routes') },
                    { path: 'prospectviewer', loadChildren: () => import('app/modules/dashboards/prospectviewer/prospectviewer.routes') },
                    { path: 'marketviewer', loadChildren: () => import('app/modules/dashboards/marketviewer/marketviewer.routes') },

                ]
            },

			{ path: "test/intentviewer", loadChildren: () => import("app/modules/test/intentviewer/intentviewer.routes")},
            { path: 'admin/settings', loadChildren: () => import('app/modules/admin-settings/admin-settings.routes') },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/error/error-404/error-404.routes') },
            { path: '401-unauthorized', pathMatch: 'full', loadChildren: () => import('app/modules/error/error-401/error-401.routes') },
            { path: 'comming-soon', pathMatch: 'full', loadChildren: () => import('app/modules/error/comming-soon/comming-soon.routes') },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
"06306c12-746f-462a-973d-dee785c25ff1"
