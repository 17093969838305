<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if(impersonation){
<impersonation-bar></impersonation-bar>
}
@if (layout === 'empty') {
<empty-layout></empty-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Centered -->
@if (layout === 'centered') {
<centered-layout [ngClass]="{'pt-12': impersonation}"></centered-layout>
}

<!-- Enterprise -->
@if (layout === 'enterprise') {
<enterprise-layout [ngClass]="{'pt-12': impersonation}"></enterprise-layout>
}

<!-- Material -->
@if (layout === 'material') {
<material-layout [ngClass]="{'pt-12': impersonation}"></material-layout>
}

<!-- Modern -->
@if (layout === 'modern') {
<modern-layout [ngClass]="{'pt-12': impersonation}"></modern-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Classic -->
@if (layout === 'classic') {
<classic-layout [ngClass]="{'pt-12': impersonation}"></classic-layout>
}

<!-- Classy -->
@if (layout === 'classy') {
<classy-layout [ngClass]="{'pt-12': impersonation}"></classy-layout>
}

<!-- Compact -->
@if (layout === 'compact') {
<compact-layout [ngClass]="{'pt-12': impersonation}"></compact-layout>
}

<!-- Dense -->
@if (layout === 'dense') {
<dense-layout [ngClass]="{'pt-12': impersonation}"></dense-layout>
}

<!-- Futuristic -->
@if (layout === 'futuristic') {
<futuristic-layout [ngClass]="{'pt-12': impersonation}"></futuristic-layout>
}

<!-- Thin -->
@if (layout === 'thin') {
<thin-layout [ngClass]="{'pt-12': impersonation}"></thin-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!--<settings></settings>-->
