import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, Input, OnDestroy, OnInit, signal, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { map, Subject, takeUntil } from 'rxjs';
import { User, UserFunction } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { NgModel, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FuseConfigService, Themes } from '@fuse/services/config';
import { CommonModule, DOCUMENT } from '@angular/common';
import { CommonService } from '../../../core/common/common.service';
import { CustomTheme } from '../../../core/common/common.types';
import { OrganizationService } from '../../../core/organization/organization.service';
import { Organization, OrganizationListData } from '../../../core/organization/organization.types';
import { isUndefined } from 'lodash';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';



@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<design></design>`,
    exportAs: 'user',
    providers: [],
    styles: [
        `
      

            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {

                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }


            .role-info {
                display: block;
                margin-top: -45px;
                color: var(--fuse-primary) !important;
            }
         
        `
    ],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatSelectModule,
        MatSliderModule,
        MatInputModule,
        CommonModule,
        MatMenuModule,
        MatTooltipModule,
        MatIconModule,
        CommonModule
    ]
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;

    public mask = [/[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, '-', /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, '-', /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/]

    user: User;
    organizationList: OrganizationListData[];

    //layout: Layout;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    customThemes: CustomTheme[];

    organizationCount: number = 0;
    organizationName: string;
    organizationRole: string;
    organizationCanEdit: boolean;

    userFunctions = signal<UserFunction | undefined>(undefined);

    private _unsubscribeAll: Subject<any> = new Subject<any>();


    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _commonService: CommonService,
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _userService: UserService,
        private _organizationService: OrganizationService,
        private _fuseConfigService: FuseConfigService,
        
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

 
    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                this._changeDetectorRef.markForCheck();
            });

        this._userService.getFunction()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((f)=>{
            this.userFunctions.set(f);
        });

          
      this._organizationService.organizationList$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((organizationList: OrganizationListData[]) => {
                this.organizationList = organizationList;

                if (this.organizationList.length >= 1) {

                    this.organizationCount = this.organizationList.length;

                    var _Org = this.organizationList.find((org) => org.active == true);

                    if (!isUndefined(_Org)) {
                        this.organizationName = _Org.organizationName;
                        this.organizationRole = _Org.organizationRole;
                        this.organizationCanEdit = _Org.canEdit;
                    }
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

  
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    public ngAfterViewInit(): void {
      //  this._designComponent.design.toggle();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        this.user.status = status;


        // Update the user
        this._userService.update({
            ...this.user
        }).subscribe();

        //this._userService.setStatus(status);


        this._userService.logActivity('action', 'change-user-status', null, status);

    }


    setActiveOrganization(organizationId: number): void {

        this._organizationService.setActive(organizationId);


        this.organizationList.forEach(org => {
            org.active = org.organizationId == organizationId;
        })


       this._userService.logActivity('action', 'change-active-organization', organizationId);


        window.location.reload();

  

       this._userService.logActivity('change_status', status);
    }

    refresh() {
        this._changeDetectorRef.detectChanges();
    }



    organization() {
        this._router.navigate(['/settings/organization']);
        this._changeDetectorRef.markForCheck();
    }
    openDrawer() {
      //  this._designComponent.openDrawer();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    settings(): void {
        this._router.navigate(['/settings/profile']);
        this._changeDetectorRef.markForCheck();
    }

    adminSettings(): void {
        this._router.navigate(['/admin/settings']);
        this._changeDetectorRef.markForCheck();
    }

    
}
