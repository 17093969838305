<!-- Bar search -->
@if(appearance === 'bar'){
<ng-container >

    @if(!opened){
    <button mat-icon-button [matTooltip]="'Suchen'"
            (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
    </button>
    }
    <div class="absolute inset-0 flex items-center shrink-0 z-99 bg-card"
         *ngIf="opened"
         @slideInTop
         @slideOutTop>
        <mat-icon class="absolute ml-6 sm:ml-8"
                  [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input class="w-full h-full px-16 sm:px-18"
               [formControl]="searchControl"
               [matAutocomplete]="matAutocomplete"
               [placeholder]="'Suchen...'"
               (keydown)="onKeydown($event)"
               #barSearchInput>
        <mat-autocomplete class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
                          [autoSelectActiveOption]="true"
                          [disableRipple]="true"
                          #matAutocomplete="matAutocomplete">
            @if(searchResults && !searchResults.length){
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent">
                Keine Ergebnisse gefunden!
            </mat-option>
            }
            <ng-container *ngFor="let searchResult of searchResults; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span class="text-sm font-semibold tracking-wider text-secondary">{{searchResult.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of searchResult.searchDataList; trackBy: trackByFn">
                    <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                                [routerLink]="result.link"
                                [value]="result.label">
                        <!-- Tasks -->
                        <ng-container>
                            <ng-container *ngTemplateOutlet="basicResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
        <button class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
                mat-icon-button
                (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
</ng-container>
}
<!-- Basic search -->
@if(appearance === 'basic'){
<ng-container>
    <div class="w-full sm:min-w-80">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon matPrefix
                      [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
            <input matInput
                   [formControl]="searchControl"
                   [matAutocomplete]="matAutocomplete"
                   [placeholder]="'Suchen...'"
                   (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete class="max-h-128 mt-1 rounded"
                          [autoSelectActiveOption]="true"
                          [disableRipple]="true"
                          #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                        *ngIf="resultSets && !resultSets.length">
                Die Suche ergab keine Treffer!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                                [routerLink]="result.link"
                                [value]="result.link">
                        <!-- Contacts -->
                        <ng-container *ngIf="resultSet.id === 'contacts'">
                            <ng-container *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Pages -->
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Tasks -->
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>
}

<!-- Contact result template -->
<ng-template #contactResult
             let-result>
    <div class="flex items-center">
        <div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
            @if(result.avatar){<img [src]="result.avatar">}
            @if(!result.avatar){ <mat-icon class="m-0 icon-size-5 text-primary dark:text-primary-400"
                                           [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>}
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>

<!-- Page result template -->
<ng-template #basicResult
             let-result>
    <div class="flex flex-col" (click)="goTo(result.link)">
        <div class="truncate leading-normal"
             [innerHTML]="result.title"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{result.link}}
        </div>
    </div>
</ng-template>

<!-- Task result template -->
<ng-template #taskResult
             let-result>
    <div class="flex items-center">
        @if(result.completed){
        <ng-container>
            <mat-icon class="mr-0 text-primary dark:text-primary-400"
                      [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        }
        @if(!result.completed){
        <ng-container>
            <mat-icon class="mr-0 text-hint"
                      [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        }
        <div class="ml-3 truncate leading-normal"
             [ngClass]="{'line-through text-hint': result.completed}"
             [innerHTML]="result.title"></div>
    </div>
</ng-template>
