import { inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class CustomPaginatorIntl extends MatPaginatorIntl  {


	itemsPerPageLabel = 'IPP';  // "Items per page" Text
	nextPageLabel = 'NP';      // "Next page" Text
	previousPageLabel = 'PP';    // "Previous page" Text
	firstPageLabel = 'FP';        // "First page" Text
	lastPageLabel = 'LP';       // "Last page" Text

	constructor(private _transloco : TranslocoService) {
		super();
		this._transloco.langChanges$.pipe(takeUntilDestroyed()).subscribe((change)=>{
			this.itemsPerPageLabel = this._transloco.translate("pagination.items-per-page");
			this.nextPageLabel = this._transloco.translate("pagination.next-page");
			this.previousPageLabel = this._transloco.translate("pagination.previous-page");
			this.firstPageLabel = this._transloco.translate("pagination.first-page");
			this.lastPageLabel = this._transloco.translate("pagination.last-page");
			this.changes.next();
		});
	}

	getRangeLabel = function (page: number, pageSize: number, length: number) {
		if (length === 0) {
			// return `0 von ${length}`;
			return this._transloco.translate("pagination.of-empty");
		}
		const startIndex = page * pageSize;
		const endIndex = Math.min(startIndex + pageSize, length);
		// return `${startIndex + 1} - ${endIndex} von ${length}`;
		return this._transloco.translate("pagination.of-template", {startIndex, endIndex, total: length});
	};
}
