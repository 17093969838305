import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, inject, provideAppInitializer } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	withInMemoryScrolling,
	withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { firstValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { MAT_PAGINATOR_INTL_PROVIDER, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './core/intl/paginator.intl';
import { CustomDatepickerIntl } from './core/intl/datepicker.intl';
import { MatDatepickerIntl } from '@angular/material/datepicker';

export const appConfig: ApplicationConfig = {

	providers: [

		provideAnimations(),
		provideHttpClient(),
		provideRouter(
			appRoutes,
			withPreloading(PreloadAllModules),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
		),


		// Material Date Adapter
		{
			provide: DateAdapter,
			useClass: LuxonDateAdapter,
		},

		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'D',
				},
				display: {
					dateInput: 'DDD',
					monthYearLabel: 'LLL yyyy',
					dateA11yLabel: 'DD',
					monthYearA11yLabel: 'LLLL yyyy',
				},
			},
		},

		// Transloco Config
		/*  provideTranslocoMessageformat({
			  biDiSupport: true,
			  strictPluralKeys: false
		  }),*/
		provideTransloco({
			config: {
				availableLangs: [
					{
						id: 'en',
						label: 'English',
					},
					{
						id: 'de',
						label: 'Deutsch'
					}
				],
				defaultLang: 'de',
				fallbackLang: 'de',
				reRenderOnLangChange: true,
				failedRetries: 2,
				prodMode: false,


			},
			loader: TranslocoHttpLoader,
		}),

		provideAppInitializer(() => {
			const initializerFn = (() => {
				const translocoService = inject(TranslocoService);
				const defaultLang = translocoService.getDefaultLang();
				translocoService.setActiveLang(defaultLang);

				return () => firstValueFrom(translocoService.load(defaultLang));
			})();
			return initializerFn();
		}),
		{
			provide: MatPaginatorIntl,
			deps: [TranslocoService],
			useClass: CustomPaginatorIntl
		},
		{
			provide: MatDatepickerIntl,
			deps: [TranslocoService],
			useClass: CustomDatepickerIntl
		},
		// Fuse
		provideAuth(),
		provideIcons(),
		provideFuse({
			mockApi: {
				delay: 0,
				services: mockApiServices,
			},
			fuse: {
				layout: 'classic',
				scheme: 'light',
				screens: {
					sm: '600px',
					md: '960px',
					lg: '1280px',
					xl: '1440px',
				},
				theme: 'theme-scalooper',
				themes: [
					{
						id: 'theme-scalooper',
						name: 'Scalooper',
					},
					{
						id: 'theme-mbmedien',
						name: 'MBmedien',
					},
					{
						id: 'theme-heise',
						name: 'Heise',
					},
					{
						id: 'theme-hbs',
						name: 'Heise Business Services',
					},
				],
			},
		}),
	],
};
