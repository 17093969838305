<ng-container *ngIf="show">

<div class="center">
    <span>Identitätswechsel</span>
    <div class="userinfo">
        <span class="px-2 bg-secondary text-white rounded-full">{{user.email}}</span>
    </div>
</div>

</ng-container>
